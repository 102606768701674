"use client";
import dynamic from 'next/dynamic'

import React, { useEffect, useRef, useState } from "react";
import {useTranslations, useLocale} from 'next-intl';
// import Select from "react-select";
import { fetchSearchSuggestions } from "./service";
import {useRouter} from '@/i18n/routing';
import { bedrooms, bedrooms_ar, price, price_ar } from '@/app/[locale]/_utils/contants';
import { formatNumberToArabic } from '../../_utils/utils';

const SearchIcon = dynamic(() => import("../../../../../assets/Icons/searchIcon.svg"))
const Select = dynamic(() => import("react-select"))
const DropDownArrow = dynamic(() => import("../../../../../assets/Icons/dropdownArrow.svg"))

const SearchSection = ({ bgColor }) => {
  const t = useTranslations('common');
  const locale = useLocale()
  const isRTL = locale === 'ar'
  // const router = useRouter()
  const [activeDropDown, setActiveDropDown] = useState(null);
  const [searchDropDown, setSearchDropDown] = useState(true);
  const [selectedMinPrice, setSelectedMinPrice] = useState(null);
  const [selectedMaxPrice, setSelectedMaxPrice] = useState(null);
  const [searchSuggestions, setSearchSuggestions] = useState(null);
  const [selectedKeywords, setSelectedKeywords] = useState();
  const [category,setCategory] = useState("RESIDENTIAL")
  const [dropDownBtn,setDropDownBtn] = useState(false)
  const dropDownRefBed = useRef(null);
  const searchDropDownRefBed = useRef(null);
  const dropDownBtnRef = useRef(null);
  const [inputValues, setInputValues] = useState({
    offer:"BUY"
  });

  const router = useRouter();

  useEffect(()=>{
    // router.prefetch("/offplan/allemirates/properties/residential/sales")
    router.prefetch("/offplan")
  },[])

  useEffect(() => {
    let handleOutClick = (e) => {
      if (!dropDownRefBed?.current?.contains(e.target)) {
        setActiveDropDown(null);
      }
      if (!searchDropDownRefBed?.current?.contains(e.target)) {
        setSearchDropDown(false);
      }
      if (!dropDownBtnRef?.current?.contains(e.target)) {
        setDropDownBtn(false);
      }
    };
    window.addEventListener("click", handleOutClick);
  }, [dropDownRefBed, searchDropDownRefBed]);

  useEffect(() => {
    if (inputValues?.text && searchDropDown) {
      getSearchSuggestion();
    }
  }, [inputValues]);

  const dropDownHandler = (e, type) => {
    e.stopPropagation();
    setActiveDropDown(type);
    setSearchDropDown(false);
  };

  const minPriceHandleChange = (value) => {
    setSelectedMinPrice(value);
    handleChange("minPrice", value?.value);
  };

  const maxPriceHandleChange = (value) => {
    setSelectedMaxPrice(value);
    handleChange("maxPrice", value?.value);
  };

  const handleUpdate = (item) => {
    const { name, value } = item?.target;
    if (name === "text") {
      setSearchDropDown(true);
    }
    handleChange(name, value);
  };

  const handleChange = (name, value, type_id) => {
    if (name === "bed") {
      setActiveDropDown(null);
    }
    if(name === "text"){
      if(selectedKeywords?.result_type){
        setSelectedKeywords(null)
      }
    }
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };



  const getSearchSuggestion = async () => {
    try {
      let query = `text=${inputValues?.text}&searchType=scndry&ctg=${category.toLowerCase()}&oft=${inputValues?.offer==="BUY"?"BY":"RT"}`
    
      const response = await fetchSearchSuggestions(query);
      if (response?.length) {
        setSearchDropDown(true);
        setActiveDropDown(null);
      }
      setSearchSuggestions(response);
    } catch (err) {}
  };

  const filterQueryResult = (type, data) => {
    if (type === "TITLE") {
      return data?.title;
    } else if (type === "CITY") {
      return data?.city;
    } else if (type === "DEVELOPER") {
      return data?.developer_name;
    }
  };

  const bedDropDownHandler = (e, count) => {
    e.stopPropagation();
    handleChange("bed", count);
    setActiveDropDown(null);
  };

  const offeringDropDownHandler = (e, value) => {
    e.stopPropagation();
    handleChange("offer", value);
    setActiveDropDown(null);
  };

  // const searchSubmitHandler = () => {
  //   let url = "";
  //   let emirate = "allemirates"
  //   if (inputValues?.bed) {
  //     url += `${inputValues?.bed}-bedrooms-or-more`;
  //   }
  //   if (inputValues?.minPrice) {
      
  //     if (url != "") {
  //       url += "/";
  //     } 
  //     url += `${inputValues?.minPrice}-aed-min`;
  //   }

  //   if (inputValues?.maxPrice) {
  //     if (url != "") {
  //       url += "/";
  //     } 
  //     url += `${inputValues?.maxPrice}-aed-max`;
  //   }
  //   if (inputValues?.text) {
  //     if (url != "") {
  //       url += "/";
  //     }
  //     if(selectedKeywords&&selectedKeywords?.result_type ==="community") {
  //       let replacedValue = selectedKeywords?.item_name?.replace(/ /g, "-") 
  //       url += `in-areas-${replacedValue?.toLowerCase()}`;
  //       let emiratesReplacedValue = selectedKeywords?.emirate?.replace(/ /g, "-")?.toLowerCase() 
  //       emirate = emiratesReplacedValue
  //     }else{
  //       let replacedValue = inputValues?.text?.replace(/ /g, "-")?.toLowerCase() 
  //       url += `in-keywords-${replacedValue}`
  //     }
  //   }

  //   // router.push(`/search${query}`)
  //   router.push(`/offplan/${emirate.toLowerCase()}/properties/residential/sales/${url}`);
  // };

  const searchSubmitHandler = () => {
    let url = "";
    let emirate = "allemirates"
    let containsQuery = false

    if (inputValues?.bed) {
      url += containsQuery ? '&' : '?'
      url += `bedroom=${inputValues?.bed}`
      containsQuery = true
    }

    if (inputValues?.minPrice) {
      url += containsQuery ? '&' : '?'
      url += `minPrice=${inputValues?.minPrice}`
      containsQuery = true
    }

    if (inputValues?.maxPrice) {
      url += containsQuery ? '&' : '?'
      url += `maxPrice=${inputValues?.maxPrice}`
      containsQuery = true
    }

    if (inputValues?.text) {
      url += containsQuery ? '&' : '?'

      if(selectedKeywords&&selectedKeywords?.result_type ==="community") {
        let replacedValue = selectedKeywords?.item_name?.replace(/ /g, "-") 
        url += `keywords=${replacedValue?.toLowerCase()}`
        let emiratesReplacedValue = selectedKeywords?.emirate?.replace(/ /g, "-")?.toLowerCase() 
        emirate = emiratesReplacedValue
      }else{
        let replacedValue = inputValues?.text?.replace(/ /g, "-")?.toLowerCase() 
        url += `keywords=${replacedValue}`
      }
      
      containsQuery = true
    }

    // router.push(`/search${query}`)
    router.push(`/${inputValues?.offer?.toLowerCase()}/${category?.toLowerCase()}/properties-for-${inputValues?.offer === "BUY"?"sale":"rent"}/${url}`);
  };

  const categoryBtnHandler = (type) => {
    if(type === "OFFPLAN"){
    router.push(`/offplan`);
    }else{
    setCategory(type)
    }
  }

  const _bedrooms = locale === 'ar' ? bedrooms_ar : bedrooms


  return (
    <div id="searchSection" className={bgColor ? `bgColor` : ``}>
      <div className="buttonDropDown" >
        <button onClick={()=>setDropDownBtn(!dropDownBtn)} ref={dropDownBtnRef}>
          <span className="text">{t(category.toLowerCase())}</span>
          <span className={`icon ${locale === 'ar' ? 'ar' : ''}`}>
            <DropDownArrow/>
          </span>
        </button>
        {
          dropDownBtn &&
          <div className="dropDown">
                <ul className="listDropdownitems gradientBorder">
                  <li onClick={(e) => categoryBtnHandler("RESIDENTIAL")}>
                    <span className="text">{t('residential')}</span>
                  </li>
                  <li onClick={(e) => categoryBtnHandler("COMMERCIAL")}>
                    <span className="text">{t('commercial')}</span>
                  </li>
                  <li onClick={(e) => categoryBtnHandler("OFFPLAN")}>
                    <span className="text">{t('offplan')}</span>
                  </li>
                </ul>
              </div>
        }
         
      </div>
      <div className={dropDownBtn?"buttonContainer active":"buttonContainer"}>
        <button
            className={`button residential ${category === "RESIDENTIAL" && "active"}`}
            data-aos="fade-up"
            data-aos-delay="50"
            onClick={()=>categoryBtnHandler("RESIDENTIAL")}
          >
            <span>{t('residential')}</span>
          </button>
          <button
            className={`button commercial ${category === "COMMERCIAL" && "active"}`}
            data-aos="fade-up"
            data-aos-delay="30"
            onClick={()=>categoryBtnHandler("COMMERCIAL")}
          >
            <span>{t('commercial')}</span>
          </button>
          <button
          className={`button offPlan ${category === "OFFPLAN" && "active"}`}
          data-aos="fade-up"
          data-aos-delay="70"
          onClick={()=>categoryBtnHandler("OFFPLAN")}
        >
          <span>{t('offplan')}</span>
        </button>
      </div>
      <div className="searchContainer">
        <div className="left">
          <div className={`types search-common ${locale === 'ar' ? 'ar' : ''}`}>
              <div className="list" onClick={(e) => dropDownHandler(e, "OFFER")}>
                <span className="details">
                {
                  inputValues?.offer ? `${t(inputValues?.offer.toLowerCase()).toUpperCase()}` : `${t('buy').toUpperCase()}`
                }
                </span>
                <span className={`icon ${locale === 'ar' ? 'ar' : ''}`}>
                    <DropDownArrow />
                </span>
              </div>
              {
                activeDropDown === "OFFER" &&
                <div className={`dropDown ${locale === 'ar' ? 'ar' : ''}`}>
                <ul className="listDropdownitems">
                  <li onClick={(e) => offeringDropDownHandler(e, "BUY")}>
                    <span className="text">{t('buy').toUpperCase()}</span>
                  </li>
                  <li onClick={(e) => offeringDropDownHandler(e, "RENT")}>
                    <span className="text">{t('rent').toUpperCase()}</span>
                  </li>
                </ul>
              </div>
              }
              
            </div>
          <div
            ref={dropDownRefBed}
            className={`beds search-common ${locale === 'ar' ? 'ar' : ''}`}
            onClick={(e) => dropDownHandler(e, "BED")}
          >
            <div className="list">
              <span className="details">
                {inputValues?.bed ? 
                isRTL ? 
                  formatNumberToArabic(inputValues?.bed) + (inputValues?.bed === 1 ? ` ${t('bed').toUpperCase()}` : inputValues?.bed === 7 ? `+ ${t('beds').toUpperCase()}` : ` ${t('beds').toUpperCase()}`)
                  :
                  inputValues?.bed + (inputValues?.bed === 1 ? ` ${t('bed').toUpperCase()}` : inputValues?.bed === 7 ? `+ ${t('beds').toUpperCase()}` : ` ${t('beds').toUpperCase()}`) 
                : 
                `${t('beds').toUpperCase()}`}
              </span>
              <span className={`icon ${locale === 'ar' ? 'ar' : ''}`}>
                  <DropDownArrow />
                </span>
            </div>
            {activeDropDown === "BED" && (
              <div className={`dropDown ${locale === 'ar' ? 'ar' : ''}`}>
                <ul className="listDropdownitems">
                  {
                    _bedrooms?.map((item)=>(
                      <li key={item?.id} onClick={(e) => bedDropDownHandler(e, item?.value)}>
                      <span className="text">{item?.label}</span>
                    </li>
                    ))
                  }
                </ul>
              </div>
            )}
          </div>
          <div
            className={`price search-common ${locale === 'ar' ? 'ar' : ''}`}
            onClick={(e) => dropDownHandler(e, "PRICE")}
          >
            <div className="list">
              <span className="details">
                {t('price').toUpperCase()}{" "}
              </span>
              <span className={`icon ${locale === 'ar' ? 'ar' : ''}`}>
                <DropDownArrow />
              </span>
            </div>
            {activeDropDown === "PRICE" && (
              <div className={`dropDown ${locale === 'ar' ? 'ar' : ''}`}>
                <div className="selectContainer">
                  <Select
                    // className="basic-single"
                    // classNamePrefix="select"
                    placeholder={t('min_price')}
                    value={selectedMinPrice || ""}
                    // isClearable
                    isSearchable
                    name="min-price"
                    options={locale === 'ar' ? price_ar : price}
                    onChange={minPriceHandleChange}
                    // getOptionLabel={getOptionLabel}
                    // getOptionValue={getOptionValue}
                  ></Select>
                </div>
                <div className="selectContainer">
                  <Select
                    placeholder={t('max_price')}
                    value={selectedMaxPrice || ""}
                    // isClearable
                    isSearchable
                    name="max-price"
                    options={locale === 'ar' ? price_ar : price}
                    onChange={maxPriceHandleChange}
                  ></Select>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="center" ref={searchDropDownRefBed}>
          <input
            type="text"
            name="text"
            value={inputValues?.text || ""}
            placeholder={t('community_or_property')}
            onChange={(item) => handleUpdate(item)}
          />
          {searchDropDown && searchSuggestions?.length > 0 && (
            <div className="dropDown">
              <ul className="listDropdownitems">
                {searchSuggestions?.map((item, index) => (
                  item?.value?.map((valueItem,secondIndex)=>(
                    <li
                    key={valueItem?.id}
                    onClick={() => {
                      handleChange("text", valueItem?.attributes?.project_name || valueItem?.attributes?.developer_name || valueItem?.attributes?.community_name || valueItem?.attributes?.property_name);
                      setSearchDropDown(false);
                      setSelectedKeywords({result_type:item?.type,emirate:valueItem?.attributes?.emirate?.data?.attributes?.emirate_name,item_name:valueItem?.attributes?.project_name || valueItem?.attributes?.developer_name || valueItem?.attributes?.slug || valueItem?.attributes?.property_name });
                    }}
                  >
                    <span className="text">{valueItem?.attributes?.project_name || valueItem?.attributes?.property_name || valueItem?.attributes?.developer_name || valueItem?.attributes?.community_name}</span>
                  </li>
                  ))
                  
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className={`right ${locale === 'ar' ? 'ar' : ''}`}>
          <SearchIcon onClick={searchSubmitHandler} />
          <button className="search globalBtn" onClick={searchSubmitHandler}>{t('search')}</button>
        </div>
      </div>
    </div>
  );
};

export default SearchSection;

"use client";
import React from "react";
import dynamic from "next/dynamic";
import { useOnInteraction } from "@/app/[locale]/_components/useOnInteraction/useOnInteraction";
import useIsMobile from "@/app/[locale]/_utils/useIsMobile";
import { useTranslations, useLocale } from 'next-intl';

const OurStory = dynamic(() => import("../OurStory/OurStory"));
const OurServices = dynamic(() => import("../OurServices/OurServices"));
const BlogSection = dynamic(() => import("../BlogsSection/BlogSection"));
import FaqSection from "../../_components/Faq/FaqSection"

const DynamicFeaturedLuxuries = dynamic(() =>
  import("../FeaturedLuxuries/FeaturedLuxuries")
);
const DynamicMeetOurTeam = dynamic(() =>
  import("../MeetOurTeam/MeetOurTeam")
);
const DynamicCommunitySection = dynamic(() =>
  import("../CommunitySection/CommunitySection")
);
const DynamicMarketOverviewSection = dynamic(() =>
  import("../MarketOverviewSection/MarketOverviewSection")
);
const PropertiesListingSection = dynamic(() =>
  import("../PropertiesListing/PropertiesListingSection")
);

const Review = dynamic(() => import('../../_components/Review/review'))

const HomePageLazyLoad = () => {

  const locale = useLocale()
  const direction = locale === 'ar' ? 'rtl' : 'ltr'
  
  const t = useTranslations('common');
  const isUserInteracted = useOnInteraction();
  const isMobile = useIsMobile({maxWidth: 768})

  // if (!isUserInteracted) {
  //   return<LoadingUI/>
  // }
  return (
    <>
      <DynamicFeaturedLuxuries />

      {isUserInteracted &&
      <>
      <div dir={direction}>
        <Review />
        <OurStory />
      </div>
      <PropertiesListingSection type={'SECONDARY-SALE'} title={t('properties_for_sale').toUpperCase()}/>
      <PropertiesListingSection type={'SECONDARY-RENT'} title={t('properties_for_rent').toUpperCase()}/>
      <div dir={direction}>
        <OurServices />
      </div>
      <DynamicMeetOurTeam />
      <DynamicCommunitySection />
      <DynamicMarketOverviewSection />
      </>
      }
      <div style={{
          border: '1px solid transparent',
          borderImage: 'radial-gradient(circle, rgba(255, 236, 224, 1) 0%, rgba(240, 202, 178, 1) 35%, rgba(172, 131, 109, 1) 100%)',
          borderImageSlice: 1,
          borderWidth: '6px',
          borderLeft: 'none',
          borderRight: 'none'
      }}>
        <FaqSection style={{marginTop: isMobile ? '5rem' : '10rem', marginBottom: isMobile ? '5rem' : '10rem'}} />
      </div>
      <BlogSection />
    </>
  );
};

export default HomePageLazyLoad;
